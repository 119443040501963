export default {
  basicViewItem: [{
    prop: 'id',
    label: '',
    Object: 'value'
  }, {
    prop: 'name',
    label: '',
    Object: 'value'
  }, {
    prop: 'name_en',
    label: '',
    Object: 'value'
  }, {
    prop: 'created_at',
    label: '',
    Object: 'value'
  }, {
    prop: 'updated_at',
    label: '',
    Object: 'value'
  }, {
    prop: 'deleted_at',
    label: '',
    Object: 'value'
  }]
}
